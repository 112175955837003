<template>
	<div>
		<template v-if="!data.AppId">
			<noOfficialAccount @refresh="getData"></noOfficialAccount>
		</template>
		<div v-else>

			<div class='home-tooltip'>
				<p>温馨提示：</p>
				<p>1. 公众号账号必须为已认证的服务号</p>
				<p>2.公众号只能授权一个企店商户，授权前检查是否已授权给其他商户或其他第三方平台</p>
				<p>3.为保证所有功能正常使用，授权时保持默认选择，把所有权限授权给企店</p>
			</div>

			<el-card class="box-card" v-loading="loading">
				<div slot="header" class="clearfix">
					<span>公众号设置</span>
					<el-button type="text" style="margin-left:20px;" @click='handleUpdate' v-if="data.AppId">更新公众号信息
					</el-button>
					<div style="float:right">
						<el-button type="text" style="margin-right:10px;" @click="openNewWindow1(1)">公众号绑定教程</el-button>
						<el-button type="text" @click="openNewWindow1(2)">公众号注册教程</el-button>
					</div>
				</div>


				<!-- 公众号公众号详细信息 -->
				<div class="program-card">
					<div class='program-info'>
						<label>公众号名称：</label>
						<span class='info'>{{data.NickName}}</span>
						<el-button type='text' class="info-button" @click="openNewWindow1(5)">重新授权</el-button>
					</div>
					<div class='program-info'>
						<label>公众号头像：</label>
						<img v-if='data.HeadUrl' :src='data.HeadUrl'
							style='width: 64px;height: 64px;object-fit: contain;'>
						<span v-else class='info'>未上传公众号头像</span>
					</div>
					<div class='program-info'>
						<!-- <div>
							<label>公众号密钥：</label>
							<span class='info'>{{data.AppSecret}}</span>
							<el-button type='text' v-show="!setInputVisible" class="info-button"
								@click="setInputVisible=true">设置</el-button>
						</div> -->
						<!-- <div v-show="setInputVisible" style="margin-top:10px;margin-left:95px;">
							<el-input v-model="officialSecret" placeholder="请输入公众号密钥" style="width:300px;"></el-input>
							<el-button type='text' class="info-button" @click="setOfficialSecret">确定</el-button>
							<el-button type='text' class="info-button"
								@click="setInputVisible= false,officialSecret= null">取消</el-button>
						</div>
						<div style="margin-top:10px;margin-left:95px;">
							<span class="remark">未设置公众号密钥，将导致部分功能不可用。</span>
							<el-button type='text' class="info-button" @click="openNewWindow1(3)">如何获取公众号密钥</el-button>
						</div> -->
					</div>
				</div>

			</el-card>
		</div>
	</div>
</template>

<script>
	import {
		wxPublicPlatFromInfo,
		wxPublicPlatFromUpdate,
		wxPublicPlatFromAppSecretEdit,
	} from '@/api/goods.js';
	
	import noOfficialAccount from '../components/noOfficialAccount.vue';
	
	export default {
		components:{
			noOfficialAccount
		},
		data() {
			return {
				loading: false,
				saveLoading: false,
				state: 0,
				data: {},

				officialSecret: null,
				setInputVisible: false,
			}
		},
		created() {
			this.getData()
		},
		methods: {
			async getData() {
				let result = await wxPublicPlatFromInfo()
				this.data = result.Result
				console.log(this.data)
			},
			openNewWindow1(index) {
				switch (index) {
					case 1:
						// window.open('https://www.yuque.com/docs/share/d93b5741-3d5e-460a-aefd-93246770816a?#')
						window.open('https://jusnn6k8al.feishu.cn/docs/doccnRpRrrnWv1pumx3wj6j7nRb')
						break;
					case 2:
						// window.open('https://www.yuque.com/docs/share/4bfc8bf9-7a5d-4f27-97e9-e32e66d221af?#')
						window.open('https://jusnn6k8al.feishu.cn/docs/doccnj7LN66b8AmkVvyXeiwGNzb')
						break;
					case 3:
						window.open('https://www.yuque.com/docs/share/be26c63f-94d6-498d-b10d-4ac16c0dec52?#')
						break;
					case 4:
						window.open('https://www.yuque.com/docs/share/8ceb022c-c6cf-4308-99cd-2a9e846af44c?#')
						break;
					case 5:
						window.open(this.data.AuthUrl)
						break;
				}
			},
			// 更新公众号
			async handleUpdate() {
				try {
					let result = await wxPublicPlatFromUpdate()
					if (result.IsSuccess) {
						this.$message({
							type: 'success',
							message: '更新成功',
							showClose: true
						})
						this.getData()
					}
				} catch (e) {}
			},
			async setOfficialSecret() {
				try {
					let result = await wxPublicPlatFromAppSecretEdit({
						AppSecret: this.officialSecret
					})
					if (result.IsSuccess) {
						this.$message({
							type: 'success',
							message: '设置成功',
							showClose: true
						})
						this.officialSecret = ''
						this.setInputVisible = false
						this.getData()
					}
				} catch (e) {}
			},
		}
	}
</script>

<style lang='less' scoped>
	.home-tooltip {
		background-color: #fff;
		padding: 10px 20px;
		font-size: 14px;
		color: #909399;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
		border: 1px solid #EBEEF5;
		border-radius: 4px;
	}

	.box-card {
		::v-deep .el-card__header {
			padding: 10px 20px;
		}

		::v-deep .el-card__body {
			display: flex;
			flex-direction: row;
		}

		margin-top: 20px;

		.clearfix {
			line-height: 36px;
		}

		.card-item1 {
			display: inline-block;
			margin-left: 100px;
			width: 400px;

			.remark {
				color: #909399;
				font-size: 13px;
				margin-top: 5px;
			}
		}

		.card-item {
			display: inline-block;
			margin-left: 100px;
			width: 400px;
			height: 200px;
			flex: 0 0 auto;
			padding: 0 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			border: 1px solid #ddd;
			border-radius: 5px;

			i {
				color: #409EFF;
				font-size: 55px;
				margin-bottom: 5px;
			}

			.remark {
				color: #909399;
				font-size: 13px;
				margin-top: 5px;
			}
		}
	}

	.program-card {
		margin-top: 10px;

		.program-info-error {
			color: #F56C6C;
			margin-left: 20px;
			font-size: 15px;
		}

		.program-info {
			margin-bottom: 20px;

			label {
				text-align: right;
				display: inline-block;
				font-size: 14px;
				color: #606266;
				font-weight: 700;
				width: 90px;
				margin-right: 5px;
			}

			.info {
				font-size: 14px;
				color: inherit;
			}

			img {
				vertical-align: top;
			}

			.info-button {
				margin-left: 10px;
			}
		}

		.remark {
			color: #909399;
			font-size: 13px;
			margin-top: 5px;
		}
	}
</style>
