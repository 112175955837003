<template>
	<div class="no-official-account">

		<el-card>
			<div slot="header" class="clearfix"><span style="font-weight: bold;">授权绑定公众号</span></div>
			
			<div class="title">绑定微信公众号，在自定义菜单等功能中，结合商品、营销活动等进行推广销售。</div>
			<div class="tip">
				<div style="margin-bottom: 15px;">
					1.若还没有公众号，
					<el-link type="primary" style="margin-right: 20px;" href="https://mp.weixin.qq.com/cgi-bin/readtemplate?t=register/step1_tmpl&lang=zh_CN&token=" target="_blank">点击此处去申请</el-link>
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnj7LN66b8AmkVvyXeiwGNzb" target="_blank">查看创建教程</el-link>
				</div>
				<div>2.若已有公众号，请阅读下方注意事项，并点击【开始绑定公众号】按钮直接绑定。</div>
			</div>
			
			<div class="help-box">
				<div class="help-title">公众号绑定注意事项：</div>
				<div class="help-content">
					<div>1.为保证所有功能正常使用，授权时请勾选所有权限授权给企店。尤其是“<span style="font-weight: bold;">开放平台帐号管理权限</span>”、“<span style="font-weight: bold;">开发管理与数据分析权限</span>”，否则将会导致无法获取用户信息</div>					
					<div>2.请使用已认证的服务号授权绑定</div>
					<div>3.若你的商城已经绑定了小程序，绑定的公众号主体需要和小程序的主体一致，否则将会导致用户数据无法在公众号和小程序间打通</div>
				</div>
				<div style="text-align: center;margin-top: 30px;"><el-button type="primary" @click="handleToBind">我已确认无误，开始绑定</el-button></div>
				<div class="auth-tip">
					授权或注册过程中，若遇到疑问，请
					<el-popover
					  width="200"
					  trigger="hover">
					  
					  <div class="service-info" style="text-align: center;">
						  <div style="font-size: 13px;font-weight: bold;margin-bottom: 5px;">添加专属客服</div>
						  <div style="font-size: 12px;color: #666;margin-bottom: 5px;">一对一为您答疑解惑</div>
						  <img style="width: 140px;" src="@/assets/img/20210719171848.jpg"></img>
					  </div>
					  
					  <el-link slot="reference" type="primary" style="margin-right: 20px;">联系客服</el-link>
					</el-popover>
					
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnRpRrrnWv1pumx3wj6j7nRb" target="_blank">查看绑定教程</el-link>
				</div>
				
			</div>
		</el-card>
		
		<el-dialog
		  title="提示"
		  :visible.sync="visible"
		  width="480px"
		  :before-close="handleClose">
		  
		  <div class="dialog-tip">
			  <div class="icon">
				  <i class="el-icon-warning"></i>
			  </div>
			  <div class="txt">授权或注册过程中，若遇到疑问，使用微信扫描下方二维码，<span style="color: #e7a43c;">添加专属客服</span>，一对一为你答疑解惑</div>
		  </div>
		  
		  <img src="@/assets/img/20210719171848.jpg" class="dialog-rwm"></img>
		  
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="visible = false" size="mini">我知道了</el-button>
		  </span>
		</el-dialog>

	</div>
</template>

<script>
	
	import {
		wxPublicPlatFromInfo,
	} from '@/api/goods.js';
	
	export default {
		components: {
		},
		data() {
			return {
				url: '',
				visible: false
			}
		},
		created() {
			this.getData();
		},
		methods: {
			async getData(callback) {
				let result = await wxPublicPlatFromInfo()
				this.url = result.Result.AuthUrl;
				
				callback && callback(result);
			},
			handleToBind(){
				window.open(this.url);
				
				this.$confirm('请在新窗口扫码完成授权绑定', '提示', {
					confirmButtonText: '我已绑定成功',
					cancelButtonText: '绑定失败',
					type: 'warning',
					closeOnClickModal: false,
					showClose: false
				}).then(() => {
					this.checkBind();
				}).catch(() => {
					this.conncatCustomerService();
				});
			},
			async checkBind(){
				this.getData(res=>{
					if (res.Result.AppId){
						this.$emit('refresh');
					}else{
						this.bindError();
					}
				})
			},
			bindError(){
				this.$confirm('授权绑定失败', '提示', {
					confirmButtonText: '重新授权',
					cancelButtonText: '联系客服',
					type: 'warning',
					closeOnClickModal: false,
				}).then(() => {
					this.handleToBind();
				}).catch(() => {
					this.conncatCustomerService();
				});
			},
			conncatCustomerService(){
				this.visible = true;
			},
			handleClose(){
				this.visible = false;
			},
		}
	}
</script>

<style lang="less" scoped>
	.no-official-account {
		.title{
			font-size: 15px;
			color: #666;
			margin-bottom: 15px;
		}
		.tip{
			font-size: 14px;
			color: #666;
			line-height: 24px;
			margin-left: 10px;
		}
		
		.help-box{
			border: 1px solid #ddd;
			padding: 15px 20px 20px;
			font-size: 13px;
			margin: 10px 20px;
			max-width: 700px;
			
			.help-title{
				color: #efc17b;
				margin-bottom: 15px;
				font-size: 15px;
			}
			.help-content{
				color: #666;
				padding-left: 10px;
				
				>div{
					margin-bottom: 10px;
					line-height: 20px;
				}
			}
		}
		
		.auth-tip{
			margin-top: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	
	.dialog-tip{
		display: flex;
		
		.icon{
			flex: 0 0 auto;
			font-size: 16px;
			color: #e7a43c;
			line-height: 22px;
			margin-top: 1px;
			margin-right: 10px;
		}
		.txt{
			flex: 1 1 auto;
			font-size: 14px;
			line-height: 22px;
		}
		
	}
	.dialog-rwm{
		display: block;
		width: 300px;
		margin: 30px auto 0;
	}
</style>
